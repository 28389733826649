import React from 'react';
import '../styles/Navbar.css'; 
import { Link } from 'react-scroll';

function Navbar() {
    return (
        <nav className="navbar">
            <ul>
                <li><Link to="home" spy={true} smooth={true} offset={0} duration={500}>Home</Link></li>
                <li><Link to="services" spy={true} smooth={true} offset={0} duration={500}>Services</Link></li>
                <li><Link to="quote" spy={true} smooth={true} offset={0} duration={500}>Quote</Link></li>
                <li><Link to="contact" spy={true} smooth={true} offset={0} duration={500}>Contact</Link></li>
            </ul>
        </nav>
    );
}

export default Navbar;
