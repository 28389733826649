import React from 'react';
import '../styles/Contact.css'; // Make sure you have this CSS file

function Contact() {
    return (
        <div className="contact contact-section" id="contact">
            <div className="contact-info">
                <div className="contact-method">
                    <i className="fas fa-phone"></i>
                    <p>(647) 767-1997</p>
                </div>
                <div className="contact-method">
                    <i className="fas fa-envelope"></i>
                    <p>thefriendlylandscaper@gmail.com</p>
                </div>
                <div className="contact-method">
                    <a href="https://facebook.com/thefriendlylandscaper" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-facebook"></i>
                        Facebook
                    </a>
                </div>
                <div className="contact-method">
                    <a href="https://ca.nextdoor.com/pages/the-friendly-landscaper-toronto-ontario/" target="_blank" rel="noopener noreferrer">
                        <i className="fa-solid fa-house"></i>
                        Nextdoor
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Contact;
