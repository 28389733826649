import React from 'react';
import QuoteForm from './QuoteForm'; // Adjust the path as necessary
import '../styles/Quote.css'; // Assuming you have some styles specific to the Quote section

function Quote() {
  return (
      <section className="quote section">
          <QuoteForm />
      </section>
  );
}

export default Quote;

