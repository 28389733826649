// Home.js
import React from 'react';
import '../styles/Home.css'; // Ensure this is linked
import LogoImage from '../assets/LogoLarge.jpg';

function Home() {
    return (
        <div className="home section">
            <div className="home-image">
                {/* Placeholder for the image */}
                <img src={LogoImage} alt="Logo" />
            </div>
            <div className="home-content">
                <h1>The Friendly Landscaper</h1>
                <p>
                    Family owned and operated, we carry on the legacy of Alex, the friendly landscaper. Specializing in transforming outdoor spaces, we proudly serve our community, bringing green dreams to life.
                </p>
                <p><strong>Locations Served:</strong> Etobicoke, Toronto, Mississauga, York</p>
            </div>
        </div>
    );
}

export default Home;
