const servicesData = [
  { id: 1, name: "Lawn Cut", description: "Detailed lawn cutting to keep your turf in top shape.", imageUrl: "src/assets/LawnCutting.jpg" },
  { id: 2, name: "Lawn Maintenance", description: "Regular maintenance to ensure a healthy, beautiful lawn all year round.", imageUrl: "https://via.placeholder.com/150" },
  { id: 3, name: "Pruning", description: "Careful pruning to enhance plant health and aesthetic appeal.", imageUrl: "https://via.placeholder.com/150" },
  { id: 4, name: "Hedge Trimming", description: "Precision hedge trimming for neat and tidy garden lines.", imageUrl: "https://via.placeholder.com/150" },
  { id: 5, name: "Mulching", description: "Quality mulching services to protect and nourish your soil.", imageUrl: "https://via.placeholder.com/150" },
  { id: 6, name: "Fertilization", description: "Expert fertilization to promote vigorous plant growth.", imageUrl: "https://via.placeholder.com/150" },
  { id: 7, name: "Spring and Fall Cleanup", description: "Comprehensive cleanup services to keep your landscape pristine in spring and fall.", imageUrl: "https://via.placeholder.com/150" }
];

export default servicesData;
