import React, { useState } from 'react';
import servicesData from '../servicesData';
import '../styles/Services.css';
import LawnCuttingImage from '../assets/LawnCutting.jpg';
import LawnMaintImage from '../assets/LawnMaint.jpg';
import HedgeImage from '../assets/Hedge.jpg';
import PruningImage from '../assets/Pruning.jpg';
import MulchImage from '../assets/Mulch.jpg';
import CleanupImage from '../assets/Cleanup.jpg';
import FertilizeImage from '../assets/Fertilize.jpg';

function Services() {
    const [expandedService, setExpandedService] = useState(servicesData[0].id);

    // Image map based on service name or ID
    const imageMap = {
        "Lawn Cut": LawnCuttingImage,
        "Lawn Maintenance": LawnMaintImage,
        // Add mappings for each service
        "Pruning": PruningImage,
        "Hedge Trimming": HedgeImage,
        "Mulching": MulchImage,
        "Spring and Fall Cleanup": CleanupImage,
        "Fertilization": FertilizeImage,
    };

    return (
        <div className="services section">
            {servicesData.map((service) => (
                <div
                    key={service.id}
                    className={`service-item ${expandedService === service.id ? 'expanded' : ''}`}
                    onMouseEnter={() => setExpandedService(service.id)}
                    style={{ backgroundImage: `url(${imageMap[service.name]})` }} // Access the image based on the service name
                >
                    <h2>{service.name}</h2>
                    {expandedService === service.id && <p>{service.description}</p>}
                </div>
            ))}
        </div>
    );
}

export default Services;
