import React, { useState } from 'react';
import '../styles/QuoteForm.css';
import emailjs from 'emailjs-com';

emailjs.init("fI0h4Dwug_F_bxGuJ");

function QuoteForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    selectedServices: [],
    lawnArea: '',
    requestDescription: '',
  });

  const serviceOptions = [
    "Lawn Cut",
    "Lawn Maintenance",
    "Pruning",
    "Hedge Trimming",
    "Mulching",
    "Fertilization",
    "Spring and Fall Cleanup",
  ];
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

     // Check if no services are selected
  if (formData.selectedServices.length === 0) {
    alert("Please select at least one service.");
    return; // Prevent form submission
  }

    //Send form using EmailJS
    emailjs.sendForm('service_r0jh2vj', 'template_cnecw1p', e.target)
    .then((response) => {
      console.log('SUCCESS!', response.status, response.text);
      alert('Your quote request has been submitted. Thank you!');
    // Reset the form after submission
    setFormData({
      name: '',
      email: '',
      phone: '',
      address: '',
      selectedServices: [],
      lawnArea: '',
      requestDescription: '',
    });
  }, (error) => {
    console.log('FAILED...', error);
    alert('There was an error submitting your request. Please try again.');
  });
  };

  const handleServiceChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      selectedServices: checked
        ? [...prevFormData.selectedServices, value]
        : prevFormData.selectedServices.filter((service) => service !== value),
    }));
  };
  

  return (
    <div className="quote-form-container">
      <h2>Request a Quote</h2>
      <form id="quoteForm" onSubmit={handleSubmit} encType='multipart/form-data'>
        {/* Required Fields */}
        <label htmlFor="name">
  Name <span className="required">*</span>
</label>
        <input
          type="text"
          name="name"
          placeholder="Name"
          required
          value={formData.name}
          onChange={handleChange}
        />
                <label htmlFor="email">
  Email <span className="required">*</span>
</label>
        <input
          type="email"
          name="email"
          placeholder="Email"
          required
          value={formData.email}
          onChange={handleChange}
        />
                <label htmlFor="phone">
  Phone Number <span className="required">*</span>
</label>
        <input
          type="tel"
          name="phone"
          placeholder="Phone #"
          required
          value={formData.phone}
          onChange={handleChange}
        />
                <label htmlFor="address">
  Address <span className="required">*</span>
</label>
        <input
          type="text"
          name="address"
          placeholder="Address"
          required
          value={formData.address}
          onChange={handleChange}
        />
        <fieldset>
  <legend>Services (select one or more)<span className="required">*</span></legend>
  {serviceOptions.map((service) => (
    <div key={service}>
      <input
        type="checkbox"
        id={service}
        name="selectedServices"
        value={service}
        checked={formData.selectedServices.includes(service)}
        onChange={handleServiceChange}
      />
      <label htmlFor={service}>{service}</label>
    </div>
  ))}
</fieldset>
        {/* Optional Fields */}
        <label htmlFor="lawnArea">
  Lawn Area
</label>
        <input
          type="text"
          name="lawnArea"
          placeholder="Estimated Lawn Area"
          value={formData.lawnArea}
          onChange={handleChange}
        />
        <label htmlFor="requestDescription">
  Description
</label>
        <textarea
          name="requestDescription"
          placeholder=""
          value={formData.requestDescription}
          onChange={handleChange}
        />
      </form>
    </div>
  );
}

export default QuoteForm;
